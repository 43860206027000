import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Text } from 'theme-ui'

const PageTermsOfUse = props => {
  return (
    <Layout {...props}>
      <Seo title='Terms Of Use' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Terms Of Use'
          subheader='By accessing or using our website, you agree to be bound by the following terms and conditions (“Terms of Use”). If you do not agree to these terms, please do not use our website.'
        />
      </Stack>
      <Stack>
        <Main>
          <Text variant='h3'>Content Disclaimer</Text>
          <Text variant='p'>The content provided on Pinout is for general informational purposes only. We strive to provide accurate and timely information, but we make no guarantees about the completeness, reliability, or accuracy of the information provided.</Text>

          <Text variant='p'>Our posts are written quickly, carefully following the trends, but may contain incorrect or unverified information. We are not responsible for any errors or omissions, or for any actions taken based on the information provided. We strongly advise you to double check and make your own investigations about the information provided in our posts.</Text>

          <Text variant='h3'>Image Sources</Text>
          <Text variant='p'>All images used on Pinout.tech are properly sourced and credited. If you believe that an image on our website is being used without proper attribution, please contact us and we will make the necessary corrections.</Text>

          <Text variant='h3'>Limitation of Liability</Text>
          <Text variant='p'>Pinout and its owners, employees, and affiliates will not be liable for any damages, including but not limited to, direct, indirect, special, incidental, or consequential damages, arising out of or in connection with the use of the website or the information provided.</Text>

          <Text variant='h3'>Changes to the Terms of Use</Text>
          <Text variant='p'>We reserve the right to make changes to these Terms of Use at any time and for any reason. Your continued use of the website following the posting of changes to these terms will mean you accept those changes.</Text>

          <Text variant='p'>By using our website, you acknowledge that you have read and understand these Terms of Use, and that you agree to be bound by them. If you do not agree to these terms, please do not use our website.</Text>

          <Text variant='p'>Thank you for using Pinout!</Text>
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageTermsOfUse
